import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const analyticsContext = React.createContext();

export const AnalyticsProvider = (props) => {
  const analytics = useState(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyCn60H8VaXAMQ-OQhL2DxdiQdESPQHVBeo",
      authDomain: "luminopixweb.firebaseapp.com",
      projectId: "luminopixweb",
      storageBucket: "luminopixweb.appspot.com",
      messagingSenderId: "700902546728",
      appId: "1:700902546728:web:982f830731ae15b84f841d",
      measurementId: "G-90ND7V1DZV"
    };

    const app = initializeApp(firebaseConfig);
    return getAnalytics(app);
  });

  return (
    <analyticsContext.Provider value={analytics}>{props.children}</analyticsContext.Provider>
  )
}

export const useAnalytics = () => {
  return React.useContext(analyticsContext);
}
