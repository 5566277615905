import { Typography } from "@mui/material";
import { Hyperlink, WaitSpinner } from "luminopix-ui-common";
import { useSearchParams } from "react-router-dom";
import { useGetTemplatesQuery } from "./redux/apiSlice";

export const GalleryItemPage = () => {
  const [urlParams] = useSearchParams();
  const templateId = urlParams.get("templateId");
  const {
    data: templates,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetTemplatesQuery();
  const template = templates?.find(template => template.id === templateId);

  if (template) {
    return (
      <>
        <Typography variant="h1">{template.name}</Typography>
        <Typography variant="body1">{template.description}</Typography>
        <Typography variant="body1"><Hyperlink to={`https://studio.luminopix.com/template?id=${template.id}`}>Download or edit in Luminopix Studio</Hyperlink></Typography>
      </>
    )
  } else if (isLoading) {
    return (
      <WaitSpinner />
    )
  } else {
    return (
      <>
        <h1>The gallery item was not found.</h1>
        <a href="/">Go to gallery homepage</a>
      </>
    )
  }
}
