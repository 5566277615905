import { Card, CardActionArea, CardContent, CardMedia, Grid, Link, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { WaitSpinner } from "luminopix-ui-common";
import { useGetTemplatesQuery } from "./redux/apiSlice"

export const HomePage = () => {
  const {
    data: templates,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetTemplatesQuery();

  const galleryView = (
    <Grid container spacing={2} >
      {
        isSuccess && templates.map((template, index) => (
          <Grid item key={index}>
            <Card sx={{width:300, height:300}}>
              <CardActionArea>
                <Link underline="none" href={`/template?templateId=${template.id}`}>
                  <CardContent>
                    <Typography>{template.name}</Typography>
                    <Typography>{template.description}</Typography>
                    {template.encodedThumbnail ? <CardMedia component='img' src={'data:image/png;base64,' + template.encodedThumbnail} /> : ''}
                  </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Grid>
        ))
      }
    </Grid>
  )

  let mainView;
  if (isSuccess) {
    mainView = galleryView;
  } else if (isLoading) {
    mainView = <WaitSpinner />
  } else if (isError) {
    mainView = <Typography variant="body1">An error was encoutered while loading gallery items. To try again, please refresh page.</Typography>
  }

  return (
    <>
      <Box sx={{objectFit:"contain"}}>
        <Box sx={{width: "50%", height: "auto", margin: "32px"}}>
          <Typography variant="h1">Luminopix Gallery</Typography>
        </Box>
      </Box>
      <Box sx={{margin: "32px"}}>
        { mainView }
      </Box>
    </>
  )
}
