import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GalleryItemPage } from './GalleryItemPage';
import { HomePage } from './HomePage';
import store from './redux/store';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { NavBar, theme, Error404 } from 'luminopix-ui-common';
import { AnalyticsProvider } from './analytics';

ReactDOM.render(
  <React.StrictMode>
    <AnalyticsProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <NavBar />
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/template" element={<GalleryItemPage />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </AnalyticsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
