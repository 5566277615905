import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getApiUrl } from 'luminopix-ui-common'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(),
    prepareHeaders: headers => {
      const pageUrl = new URL(window.location.href);
      headers.set('Access-Control-Allow-Origin', `${pageUrl.protocol}//${pageUrl.hostname}`)
      return headers
    }
  }),
  keepUnusedDataFor: 2,
  endpoints: builder => ({
    getTemplates: builder.query({
      query: () => '/solid'
    }),
    getBorders: builder.query({
      query: () => '/border'
    }),
    getStands: builder.query({
      query: () => '/stand'
    }),
    getMasks: builder.query({
      query: () => '/mask'
    }),
  })
});

export const { useGetTemplatesQuery, useGetBordersQuery, useGetStandsQuery, useGetMasksQuery } = apiSlice;
